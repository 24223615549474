import React, { useEffect, useState, Suspense } from "react";
import styles from "./opt-in-out.styles.css";
import { bubblesStream } from "./opt-in-out.js";
import BubblesList from "./bubbles-list.component.js";
const CpIcon = React.lazy(() =>
  SystemJS.import("canopy-styleguide!sofe").then((sg) =>
    Promise.resolve({ default: sg.CpIcon })
  )
);

export default function OptInOut() {
  const [featureBubbles, setFeatureBubbles] = useState([]);
  const [showBubbles, setShowBubbles] = useState(false);
  const isLoginPage = /login|logout.*redirect_url/.test(window.location.hash);

  useEffect(() => {
    const sub = bubblesStream().subscribe((bubbles) => {
      const visibleBubbles = bubbles.filter((bubble) => !bubble.hide);
      setFeatureBubbles(visibleBubbles);
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  if (featureBubbles.length === 0 || isLoginPage) {
    return null;
  } else {
    return (
      <div className={styles.optInOut}>
        <div>
          {showBubbles ? (
            <BubblesList
              bubbles={featureBubbles}
              close={() => setShowBubbles(false)}
            />
          ) : null}
          <div className={styles.bubbleWrapper}>
            <button
              onClick={() => setShowBubbles(!showBubbles)}
              className={`${styles.bubble} ${styles.flexCenter}`}
            >
              <Suspense fallback={null}>
                <CpIcon
                  name="misc-gift-box"
                  fill="var(--cp-color-app-primary-dark-text)"
                />
              </Suspense>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
