import { track } from "cp-analytics";

export function notifyAnalytics(user, eventName, dataObject, isOptOut) {
  if (!user) {
    return;
  }
  const user_id = user.id;
  const tenant_id = user.tenant_id;
  const dataObj = { user_id, tenant_id, ...dataObject };

  track("events", isOptOut ? "opt_out" : "opt_in", eventName, dataObj);
}
