// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-opt-in-out-bubbles-list-styles__bubblesList--EQ7X5 {
  position: relative;
  margin: 8px 0;
  padding: 16px 24px;
  width: 360px;
  overflow-y: auto;
}

.src-opt-in-out-bubbles-list-styles__closeIcon--Yg3u7 {
  position: absolute;
  right: 12px;
  top: 12px;
}

.src-opt-in-out-bubbles-list-styles__oldVersion--RpAyE {
  text-decoration: underline;
}

.src-opt-in-out-bubbles-list-styles__actionBlock--VRDdG {
  display: flex;
  justify-content: space-between;
}

.src-opt-in-out-bubbles-list-styles__actionBlock--VRDdG .src-opt-in-out-bubbles-list-styles__primaryAction--eYkum {
  margin: 0 auto 8px auto;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/opt-in-out/bubbles-list.styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".bubblesList {\n  position: relative;\n  margin: 8px 0;\n  padding: 16px 24px;\n  width: 360px;\n  overflow-y: auto;\n}\n\n.closeIcon {\n  position: absolute;\n  right: 12px;\n  top: 12px;\n}\n\n.oldVersion {\n  text-decoration: underline;\n}\n\n.actionBlock {\n  display: flex;\n  justify-content: space-between;\n}\n\n.actionBlock .primaryAction {\n  margin: 0 auto 8px auto;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bubblesList": `src-opt-in-out-bubbles-list-styles__bubblesList--EQ7X5`,
	"closeIcon": `src-opt-in-out-bubbles-list-styles__closeIcon--Yg3u7`,
	"oldVersion": `src-opt-in-out-bubbles-list-styles__oldVersion--RpAyE`,
	"actionBlock": `src-opt-in-out-bubbles-list-styles__actionBlock--VRDdG`,
	"primaryAction": `src-opt-in-out-bubbles-list-styles__primaryAction--eYkum`
};
export default ___CSS_LOADER_EXPORT___;
