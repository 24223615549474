import { useState, useEffect } from "react";
import { featureBubble } from "./opt-in-out.js";

export function useFeatureBubble(props = {}) {
  const { title, toggle, expirationDate, strategy, documentationUrl, hide } =
    props;
  const [active, setActive] = useState(null);
  useEffect(() => {
    if (toggle && title) {
      const sub = featureBubble({
        title,
        toggle,
        expirationDate,
        strategy,
        documentationUrl,
        hide,
      }).subscribe((v) => setActive(v));
      return () => {
        sub.unsubscribe();
      };
    } else {
      throw new Error(
        "To use the hook `useFeatureBubble` you must provide a toggle and a title"
      );
    }
  }, [title, toggle, expirationDate, strategy, documentationUrl, hide]);
  return active;
}
