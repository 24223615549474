// This is in its own file so that we can test it without exporting it from the entry file
export let cachedToggles = window.__canopy_toggles || {};

// For testing purposes
export function resetToggles() {
  cachedToggles = {};
}

// For testing purposes
export function setCache(toggles) {
  cachedToggles = toggles;
}
