// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-opt-in-out-opt-in-out-styles__optInOut--WNfCr {
  z-index: 9998;
  position: fixed;
  bottom: 12px;
  right: 14px;
}

.src-opt-in-out-opt-in-out-styles__bubble--de98_ {
  border: 0;
  padding: 0;
  background-color: var(--cp-color-default-badge-bg);
  height: 32px;
  width: 32px;
  border-radius: 8px;
}

.src-opt-in-out-opt-in-out-styles__flexCenter--ZtDG_ {
  display: flex;
  justify-content: center;
  align-items: center;
}

.src-opt-in-out-opt-in-out-styles__bubbleWrapper--uMoz0 {
  float: right;
}
`, "",{"version":3,"sources":["webpack://./src/opt-in-out/opt-in-out.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,SAAS;EACT,UAAU;EACV,kDAAkD;EAClD,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".optInOut {\n  z-index: 9998;\n  position: fixed;\n  bottom: 12px;\n  right: 14px;\n}\n\n.bubble {\n  border: 0;\n  padding: 0;\n  background-color: var(--cp-color-default-badge-bg);\n  height: 32px;\n  width: 32px;\n  border-radius: 8px;\n}\n\n.flexCenter {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.bubbleWrapper {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optInOut": `src-opt-in-out-opt-in-out-styles__optInOut--WNfCr`,
	"bubble": `src-opt-in-out-opt-in-out-styles__bubble--de98_`,
	"flexCenter": `src-opt-in-out-opt-in-out-styles__flexCenter--ZtDG_`,
	"bubbleWrapper": `src-opt-in-out-opt-in-out-styles__bubbleWrapper--uMoz0`
};
export default ___CSS_LOADER_EXPORT___;
