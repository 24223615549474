import { ReplaySubject, Subject, from, of } from "rxjs";
import { map, mergeScan, finalize, switchMap } from "rxjs/operators";
import { featureEnabled, fetchFeatureToggles } from "../feature-toggles.js";
import moment from "moment";
import { changePreference, getPreference } from "./opt-in-out.storage.js";

/*
 * internalState
 * {
 *   toggleAsKey: {
 *     obs: ReplaySubject,
 *     count: number of listeners
 *   }
 * }
 */
const internalState = {};

const bubblesReducerSubject = new Subject().pipe(
  mergeScan((acc, event) => {
    switch (event.type) {
      case "add":
        return handleAdd(acc, event.payload);
      case "update":
        return handleUpdate(acc, event.payload);
      case "remove":
        return handleRemove(acc, event.payload);
      case "reset":
        return of({});
      default:
        return of(acc);
    }
  }, {})
  /*
   *  {
   *    toggleAsKey: {
   *      active: null || true || false,
   *      title: string,
   *      strategy: 'opt-in' || 'opt-out',
   *      expirationDate: date as string
   *    }
   *  }
   */
);

const bubblesStream$ = new ReplaySubject(1);

bubblesReducerSubject.subscribe((v) => {
  bubblesStream$.next(v);
});

bubblesReducerSubject.next({ type: "reset" });

export const bubblesStream = () => {
  return bubblesStream$.asObservable().pipe(
    map((objRepresentation) => {
      const keys = Object.keys(objRepresentation);
      return keys.map((key) => {
        const obj = objRepresentation[key];
        const {
          title,
          expirationDate,
          strategy,
          active,
          message,
          documentationUrl,
          hide,
        } = obj;
        return {
          toggle: key,
          title,
          expirationDate,
          strategy,
          active,
          message,
          documentationUrl,
          hide,
          update: (value, days) =>
            bubblesReducerSubject.next({
              type: "update",
              payload: { value, toggle: key, strategy, days },
            }),
        };
      });
    })
  );
};

// Main api
export function featureBubble(options) {
  if (options.strategy === undefined) {
    options.strategy = "opt-out";
  }
  validateOptions(options);
  return options.expirationDate
    ? checkForExpiredOption(options)
    : addValidBubbleToStream(options);
}

function validateOptions(options) {
  const { toggle, title } = options;
  if (toggle == undefined) {
    throw Error("toggle is required to use opt in/out");
  }
  if (title == undefined) {
    throw Error("title is required to use opt in/out");
  }
}

function checkForExpiredOption(options, id) {
  const today = moment(window.appLoaderInitialTime);
  const expirationDate = moment(options.expirationDate);
  if (today.isBefore(expirationDate)) {
    return addValidBubbleToStream(options, id);
  } else {
    return from(fetchFeatureToggles(options.toggle)).pipe(
      switchMap(() => of(featureEnabled(options.toggle)))
    );
  }
}

function addValidBubbleToStream(options) {
  const { toggle, hide } = options;
  let responseObservable;
  const key = hide ? `${toggle}_hidden` : toggle;
  return from(fetchFeatureToggles(toggle)).pipe(
    switchMap(() => {
      if (!featureEnabled(toggle)) {
        return of(false);
      } else {
        if (internalState[key]) {
          // update internalState
          internalState[key].count++;
          responseObservable = internalState[key].obs;
        } else {
          // add to internalState
          responseObservable = new ReplaySubject(1);
          internalState[key] = {
            count: 1,
            obs: responseObservable,
          };
          // trigger update to ux state
          bubblesReducerSubject.next({ type: "add", payload: { options } });
        }
      }
      return responseObservable.asObservable().pipe(
        // mirror the source and call a callback when the subscriber unsubscribes
        finalize(() => {
          const match = internalState[key];
          if (match.count === 1) {
            internalState[key].obs.complete();
            delete internalState[key];
            bubblesReducerSubject.next({
              type: "remove",
              payload: options.toggle,
            });
          } else {
            internalState[key].count--;
          }
        })
      );
    })
  );
}

function updateObsValue(toggle, newValue) {
  internalState[toggle]?.obs?.next?.(newValue);
  internalState[`${toggle}_hidden`]?.obs?.next?.(newValue);
}

/*
 * mergeScan (async) updates
 */

function handleAdd(currentInternalRep, { options }) {
  const {
    toggle,
    title,
    strategy,
    expirationDate,
    message,
    documentationUrl,
    hide,
  } = options;
  const key = hide ? `${toggle}_hidden` : toggle;
  const existing = currentInternalRep[key];
  if (existing) {
    return of(currentInternalRep);
  } else {
    const internalAdd = {
      toggle,
      active: null,
      title,
      strategy,
      expirationDate,
      message,
      documentationUrl,
      hide,
    };
    return from(getPreference(toggle, strategy)).pipe(
      map((pref) => {
        const defaultValue = strategy === "opt-out";
        const active = pref === undefined ? defaultValue : pref;
        // update internalState source observable
        updateObsValue(key, active);
        internalAdd.active = active;
        currentInternalRep[key] = internalAdd;
        return currentInternalRep;
      })
    );
  }
}

function handleUpdate(currentInternalRep, { value, toggle, strategy, days }) {
  // updates require updating 3 areas
  // 1. Local Storage preference
  // 2. Calling provided callback to update state (component state outside opt-in/opt-out)
  // 3. Updating the array provided to the opt-in-out component so we show the correct button (new vs old)
  return from(
    // 1. Local storage
    changePreference(toggle, value, strategy, days)
  ).pipe(
    // 2. update stream
    // 3. Update the internalRepresentation
    map(() => {
      currentInternalRep[toggle].active = value;
      updateObsValue(toggle, value);
      return currentInternalRep;
    })
  );
}

function handleRemove(currentInternalRep, toggle) {
  delete currentInternalRep[toggle];
  return of(currentInternalRep);
}

// for testing only
export function testingONLY_reset() {
  bubblesReducerSubject.next({ type: "reset" });
}

export function testingONLY_getCounts() {
  const keys = Object.keys(internalState);
  const counts = keys.reduce((acc, key) => {
    acc[key] = internalState[key].count;
    return acc;
  }, {});
  return counts;
}
