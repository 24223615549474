import React, { Suspense, useState, useEffect } from "react";
import styles from "./bubbles-list.styles.css";
import IndividualFeatureBubble from "./feature-bubble.component.js";
const CpButton = React.lazy(() =>
  SystemJS.import("canopy-styleguide!sofe").then((sg) =>
    Promise.resolve({ default: sg.CpButton })
  )
);

export default function BubblesList(props) {
  const { bubbles, close } = props;
  const [navHeight, setHeight] = useState(0);
  useEffect(() => {
    let subscription;
    Promise.all([
      SystemJS.import("primary-navbar!sofe"),
      SystemJS.import("rxjs/operators"),
    ]).then(([nav, operators]) => {
      subscription = nav.primaryNavHeightObs.subscribe((newNavHeight) => {
        setHeight(newNavHeight);
      });
    });
    return () => {
      subscription && subscription.unsubscribe();
    };
  }, []);

  return (
    <div
      className={`${styles.bubblesList} cps-card__height-3`}
      style={{
        maxHeight: `calc(100vh - ${navHeight}px - ${32}px /* icon */ - ${16}px /* icon padding */ - ${8}px /* top padding */)`,
      }}
    >
      <Suspense fallback={null}>
        <div className={styles.closeIcon}>
          <CpButton icon="close-small" aria-label="close" onClick={close} />
        </div>
        {[...bubbles]
          .sort((a, b) => b?.expirationDate.localeCompare(a?.expirationDate))
          .map((bubble, index) => {
            return (
              <IndividualFeatureBubble
                key={bubble.toggle}
                bubble={bubble}
                close={close}
                bottomHR={index !== bubbles.length - 1}
              />
            );
          })}
      </Suspense>
    </div>
  );
}
